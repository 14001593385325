import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subscribe } from '../../core/constants/subscribe';

@Injectable({
  providedIn: 'root'
})
export class SubscribeService {
  private apiUrl = 'https://api.marvtech-ai.com/subscribe.php';

  constructor(private http: HttpClient) { }

  subscribe(formData: FormData): Observable<Subscribe> {
    return this.http.post<Subscribe>(this.apiUrl, formData, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
      }
    });
  }
}
